import React from 'react';
import LinkButton from '../LinkButton';
import MenuBar from '../MenuBar';
import WhatsappIcon from '../../components/WhatsappIcon';
import ScrollIcon from '../../components/ScrollIcon';
import Button  from '../Button';
import './index.scss';
import { Outlet, Link } from "react-router-dom";


export default function Layout(){

    

    const Links = [
        { id: 1, text: 'Home', link: "/home" },
        { id: 2, text: 'Services', link: "#services" },
        { id: 3, text: 'About Us', link: "/about" },
        { id: 3, text: 'Logistics', link: "/transport" },
        { id: 3, text: 'Mining', link: "/mining" }
      
    ];

    let icon = () => {
        return (
            <>
                <i className='fa fa-angle-down'></i>
            </>

        )
    }
    let icon1 = () => {
        return (
            <>
                <i className='fa fa-angle-up'></i>
            </>

        )
    }

    let scrool = () => {
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }
    return (
        <>
         <div className="nav container-fluid animate__animated m-0 py-2 w-100 " id="header-bar">
                    <div className="title container-fluid "> <h3 className='text-center'>KGA LOGISTICS</h3>  </div>
                    <div className='w-auto m-0 p-0 nav-bar-items d-flex flex-direction-row'>
                        {Links.map((Links) => <LinkButton key={Links.id} text={Links.text} href={Links.link} />)}
                    </div>
                    <div className='header-contacts text-white'>
                        <ul className='d-flex flex-column'>
                            <li className='ml-1'><i className="fa-solid fa-phone mr-1"></i>Tel : +27 67 884 4843</li>
                            <li className='ml-1'><i className="fa-solid fa-envelope mr-1"></i>info@kgagamelaholdings.co.za</li>
                        </ul>
                    </div>
                    <MenuBar />
                </div>
                <ScrollIcon href="#footer" id="scroll1" icon={icon()} />
                <ScrollIcon href="#home" id="scroll2" icon={icon1()} />
               
<Outlet />
        </>
        
    )
}