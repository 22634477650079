import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import Button from '../Button'
import Slider from '../Slider';

import img from '../../assets/img/office.jpeg';

import img1 from '../../assets/img/trucks front.jpg';

import LinkButton from '../LinkButton';

import MenuBar from '../MenuBar';
import { Link } from 'react-router-dom';

import WhatsappIcon from '../../components/WhatsappIcon';

import ScrollIcon from '../../components/ScrollIcon';

import logo from '../../assets/img/kga_logo-removebg-preview.png'

import logo1 from '../../assets/img/kga_logo-removebg-preview.png'


//This is to add remove classes from animated elements so that they can animate again when the state changes

function getSlide(list) {
    let readMore = () => {

        document.getElementById(list.more[1]).style.display = "block";
    }
    return (
        <>
            <Slider title={list.title} caption={list.caption} imgURL={list.imgURL} alt={list.alt} logo={list.logo} more={list.more[0]} moreid={list.more[1]} onClick={readMore} />
        </>
    )
}
//{list.more.text}
let mission = "Kgagamela Holdings is a freight company specialising in line haul logistics and employee transport as it is incorporated Kgwana Transport. Operating for more than seven years, the company has rapidly grown to become one of regional..."; // However our fundamental administrative strategy is based on idea sharing, technologies and talents thus ensuring and facilitating endurable profitable growth of the organization. Team work demands strong relationships and communication skills thereby openly building trust and honouring commitments

let vision = "Our business is situated along the R37 Route, which is the main road that connects Burgersfort and Polokwane, is on this area that there is vast deposit of minerals; this comes with the shortage of black owned companies that can synergise..."
const Sliders = [
    { title: "Logistics", caption: mission, imgURL: img1, alt: "backgroud img", logo: logo1, more: ["", "readMoreMission"] },
    { title: "Mining ", caption: vision, imgURL: img, alt: "backgroud img", logo: logo, more: ["", "readmoreVisison"] }

]

export default function Header() {

    const [indexSlide, setIndexSlide] = useState(0);


    const [currentSlide, setSlide] = useState(getSlide(Sliders[indexSlide]));

    const changeSlider = (index1) => {

        if (window.scrollY <= 10) {
            if (indexSlide < Sliders.length - 1 && indexSlide >= 0) {
                let newVal = indexSlide + index1;
                if (newVal != -1) {
                    setIndexSlide(newVal);
                }
                else {
                    setIndexSlide(1);
                }

                console.log(indexSlide);
                setSlide(getSlide(Sliders[indexSlide]));


            }
            else {
                setIndexSlide(0);
                setSlide(getSlide(Sliders[indexSlide]));


            }
        }
        else {
            if (indexSlide != 0) {
                setIndexSlide(0);
                setSlide(getSlide(Sliders[0]));
            }

        }


    }



    useEffect(() => {



        const setint = setInterval(() => {

            if (window.scrollY <= 10) {
                if (indexSlide < Sliders.length - 1 && indexSlide >= 0) {
                    let newVal = indexSlide + 1;
                    if (indexSlide != -1) {
                        setIndexSlide(newVal);
                    }


                    setSlide(getSlide(Sliders[indexSlide]));

                }
                else {
                    setIndexSlide(0);
                    setSlide(getSlide(Sliders[indexSlide]));


                }
            }
            else {
                if (indexSlide != 0) {
                    setIndexSlide(0);
                    setSlide(getSlide(Sliders[0]));
                }

            }


        }, 30000);



        return () => clearInterval(setint);
    }

        , [])




    return (

        <>
            <div className='header-container m-0 px-0 py-0'>
               
                {/* <div className='banner-container h-100 m-0 p-0' id="banner-container">
                <div className='banner-header animate__animated animate__slow' id="banner-header"></div>
            </div> */}


                {currentSlide}


                {/* <WhatsappIcon phone="+27678844843" /> */}
                 <div className='button-slider animate__animated animate__fadeInUp animate__slow m-0 p-0'>
                <a href={'about'} className='bg-light text-dark px-3 py-1 rounded-pill border shadow second-theme' icon="fa-arrow-right" > <b>Learn More</b></a>
            </div> 
             <div className='control-icons next-button px-3 py-3 m-0 text-white hover' onClick={() => changeSlider(1)}><i className='fa fa-angle-right'></i></div>
                <div className='control-icons prev-button px-3 py-3 m-0 text-white hover' onClick={() => changeSlider(-1)}><i className='fa fa-angle-left'></i></div>
           
                </div>
        </>



    )
}