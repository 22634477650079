import Aos from 'aos';
import React, { useEffect, useState } from 'react';
import img from '../../assets/img/trucksside.jpg';
import Button from '../Button';
import './about.scss';
import { Link } from 'react-router-dom';

const About = () => {

    const [readMore, setReadMore] = useState(false); //set readMore to be false by default

    //collapse the content 

    let readMore1 = `
    The Kgagamela Holdings offers comprehensive, value-added services to create an environment that minimizes risk and maximizes returns for our customers - allowing projects to be delivered on time and on budget, with no surprises. Our collaborative project approach sets the standard for communication with our customers to provide client-driven solutions and satisfaction.

    We pride ourselves on being very flexible and reacting quickly to customer needs as well as market demands. We are a service organization whose mission is to provide efficient solutions to our customers’ challenges.
`;
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, [])

    let scrool = () => {
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }


    return (
        <div id='about' className='py-4'>
            <div className='container'>
                <h2 className='fw-bold text-uppercase my-3'>About us </h2>
                <div className='row align-items-center'>
                    <div className='col-xl-6'>
                        <div className='item' data-aos="fade-up">
                            <img src={img} className="w-100 rounded mb-4 mb-xl-0" alt="" />
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='item ' data-aos="fade-down">
                            <div className='captions  '>

                                <div className='text'>

                                    <p>
                                        Since 2004, our customers have come to know and trust the Kgagamela Holdings Standard for Construction. Our core values of integrity, reliability and innovation are integral to delivering a superior customer experience. We provide economical and efficient solution to our client’s challenges. We take ownership of the issues and understand our responsibility and commitment to our clients.

                                        Kgagamela Holdings is a full-service construction management and general contracting firm that operate mainly in the Limpopo. We are a vertically integrated organization, composed of a variety of business units organized around industry sectors. The business units are very diverse and each unit specializes in its own core industry. This structure allows each division to operate and develop expertise based on the demands of its specific industry. It also provides stability for the company as market forces vary within different market sectors... 
                                        

                                    </p>

                                    <a href={'/about'} className='btn-group bg-theme shadow px-4 py-1 text-white rounded-pill hover'> Read More </a>

                                </div>
                                {/* 
                                <div className='conect-button'>
                                </div>
                                <Button text="connect with us" onClick={scrool} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About