import React from 'react';
import './index.scss';


export default function LinkButton(props){
    let hover = (event)=>{
        let links = document.getElementsByClassName("link-container");
        for (let i =0; i < links.length; i++){
                links.classList.remove("active");
        }
        event.targent.classList.add("active");
    }
    return(
        <>
        <div className="link-container m-1" onClick={hover}> 
           <div className="link-name"><a href={props.href} className='p-2'> {props.text}</a></div> 
            <div className="container-fluid m-0 p-0 bg-effect-hover"></div>
      </div>
        </>
    )
   
}
