import React from 'react'
import './contact.scss'

const Contact = () => {
    return (
        <div id='Contact' className='py-4'>
            <div className='container'>
                <h2 className='text-center fw-bold text-uppercase mb-4'>  Contact</h2>
                <div className='border p-3'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='item border p-3 text-center mb-4 m-lg-0' data-aos="fade-down">
                                <h6>Find US</h6>
                                <hr style={{ borderWidth: 2 }} />
                                <div className='contact-location'>
                                    <p className='m-0'>R37 Road Riba-Cross</p>
                                    <p className='m-0'>Driekop</p>
                                    <p className='m-0'>Burgersfort</p>
                                    <p className='m-0'>South Africa</p>
                                    <p className='m-0'>1150</p>
                                    <p className='m-0'>  Operating Hours: 08:15 - 16:30 Mon-Fri</p>
                                </div>
                                <ul className='list-unstyled findus-info mt-3'>
                                    <li>
                                        <i className="fa-solid fa-envelope"></i><a href="mailto:info@medsmadiagane.co.za">info@kgagamelaholdings.co.za</a>
                                    </li>
                                    <li className='text-left my-1'>
                                        <span className='bg-secondary rounded px-3 py-1 text-white '> Operations Contacts </span>
                                        <br />
                                        <i className="fa-solid fa-phone"></i>
                                        <a href="tel:+27 67 884 4843" className='text-small'><small>+27 72 828 4205</small></a> /
                                        <a href="tel:+27 79 184 4968"><small>+27 79 370 4970 </small></a>
                                    </li>
                                    <li className='text-left my-1'>
                                        <span className='bg-secondary rounded px-3 py-1 text-white '> Admin Contacts </span>
                                        <br />
                                        <i className="fa-solid fa-phone"></i>
                                        <a href="tel:+27 67 884 4843" className='text-small'><small>083 254 1762</small></a>
                                    </li>
                                    <li className='my-2 text-left'>
                                        <hr className='bg-light w-90 mx-auto' /><i className="fa-solid fa-clock"></i> Operating Hours: 08:15 - 16:30 Mon-Fri</li>
                                </ul>
                                <ul className='list-unstyled contact-socials d-flex justify-content-center mt-4'>
                                    <li className='facebook'><a href="facebook"><i className="fa-brands fa-facebook-f"></i></a></li>
                                    <li className='whatsapp'><a href="whatsapp://send?text=Hi!&amp;phone=+27678844843"><i className="fa-brands fa-whatsapp"></i></a></li>
                                    <li className='twitter'><a href="twitter"><i className="fa-brands fa-twitter"></i></a></li>
                                    <li className='instagram'><a href="instagram"><i className="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <form action="" className='border p-3  contact-form' data-aos="fade-up">
                                <div className='row '>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" id="name" placeholder='Name:' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <input type="number" className="form-control" id="phone" placeholder='Phone:' />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" id="EmailAddress" placeholder='Email Address:' />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Message" id="floatingTextarea2" style={{ height: 200 }} defaultValue={""} ></textarea>

                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button type='submit' className='btn mt-3'>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='mt-5 p-3 border'>
                        <iframe data-aos="zoom-in" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3627.018003437268!2d30.188219675675885!3d-24.623064378085008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDM3JzIzLjAiUyAzMMKwMTEnMjYuOSJF!5e0!3m2!1sen!2sza!4v1692096723444!5m2!1sen!2sza" width={"100%"} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact