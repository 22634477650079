import Aos from 'aos';
import React, { useEffect, useState } from 'react';
import img from '../../assets/img/trucksside.jpg';
import { Link } from 'react-router-dom';
import Button from '../Button';
import './index.scss';
import Footer from '../footer/Footer';
import about1 from '../../assets/img/office.jpeg'
import about2 from '../../assets/img/trucks front.jpg'
import about3 from '../../assets/img/offices.jpeg'

const Transport = () => {

    const [readMore, setReadMore] = useState(false); //set readMore to be false by default

    //collapse the content 

    let readMore1 = `
    The Kgagamela Holdings offers comprehensive, value-added services to create an environment that minimizes risk and maximizes returns for our customers - allowing projects to be delivered on time and on budget, with no surprises. Our collaborative project approach sets the standard for communication with our customers to provide client-driven solutions and satisfaction.

    We pride ourselves on being very flexible and reacting quickly to customer needs as well as market demands. We are a service organization whose mission is to provide efficient solutions to our customers’ challenges.
`;
    useEffect(() => {
        Aos.init({ duration: 1500 });

        let el1 =  document.getElementById("header-bar");

        el1.classList.remove("in-viewport");
        ;
       el1.classList.add("out-viewport");
       el1.classList.remove("animate__fadeInDown");
       
         el1.classList.add("animate__fadeInDown");
        // el4.style.display = "none";


    }, [])

    let scrool = () => {
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }

    const about = [
        {
            name: `Since 2004, our customers have come to know and trust the Kgagamela Holdings Standard for Construction. Our core values of integrity, reliability and innovation are integral to delivering a superior customer experience. We provide economical and efficient solution to our client’s challenges. We take ownership of
             the issues and understand our responsibility and commitment to our clients.`,
            url: about1,
            title: 'Our Values'
        },
        {
            name: `Kgagamela Holdings is a full-service construction management and general 
            contracting firm that operate mainly in the Limpopo. We are a vertically integrated organization, composed of a variety of business units organized around industry sectors. The business units are very diverse and each unit specializes in its own core industry. This structure allows each division to operate and develop expertise based on the demands of its specific industry. It also provides stability 
            for the company as market forces vary within different market sectors.`,
            url: about2,
            title: 'Core Services'
        },
        {
            name: `The Kgagamela Holdings offers comprehensive, value-added services to create an
             environment that minimizes risk and maximizes returns for our customers - allowing
              projects to be delivered on time and on budget, with no surprises. Our collaborative 
              project approach sets the standard for 
            communication with our customers to provide client-driven solutions and satisfaction.`,
            url: about3,
            title: 'Why Choose Us'
        }
    ];

    return (
        <>
       <div id='Contact' className='py-4 mt-4 mx-0 w-100'>
            <div className='container-fluid mt-4 mx-0 '>
                <h2 className='text-center fw-bold text-uppercase mb-4 mx-0'>  Logistics Sector</h2>
                <div className=' py-3 px-0 mx-0 w-100'>
                    <div className='px-3 py-2 mx-auto  text-center bg-second-theme text-white transport-btn'>
                    <b>Transportation / Logistics</b>
                    </div>
                    <div className='row align-items-center w-100'>
                    <div className='col-xl-6 mt-4'>
                        <div className='item' data-aos="fade-up">
                            <img src={about2} className="w-100 rounded mb-4 mb-xl-0" alt="" />
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='item border shadow px-3 py-2 rounded' data-aos="fade-down">
                          
                              

                                    <p>
                                    Kgagamela Holdings is a freight company specialising in line haul logistics and employee transport as it is incorporated Kgwana Transport. Operating for more than seven years, the company has rapidly grown to become one of regional Limpopo’s most in-demand independent freight and employee businesses. Founded by Managing Member Norman Mahabe, the Kgagamela Holdings team has more than 25 years experience in general transport and taxi business.
                                    </p>

                                    <p>
                                    Servicing mines and independent companies, Kgagamela Holdings offers a wide and varied freight service and employees transport. This included staff transport, building materials and general freight along with high security items such as electrical and entertainment appliances, tobacco, alcohol and medicinal supplies.
                                    </p>
                                    <p>
                                    Kgagamela Holdings prides itself on honest, reliable and excellent customer service, treating each customer and freight haul with the highest level of personal attention, professionalism and confidentiality. All trucks include 24 hour satellite tracking equipment providing up to minute monitoring of consignment. Each and every haul is treated with the utmost care, arriving at destinations on time and in perfect condition.
                                    </p>

                           
                        </div>
                    </div>
                </div>
                   
                  
                </div>
                <hr />
               
            </div>
        </div>

        <Footer />
        </>
    )
}

export default Transport