import React, { Component, useEffect } from 'react'
import './index.scss'


export default function Slider(props) {

    setTimeout(() => {
        document.getElementsByClassName("title-slider")[0].style.display = "block";




    }, 1700);
    setTimeout(() => {
        document.getElementsByClassName("container-slider")[0].classList.remove("animate__fadeInRight");
    }, 1070);
    setTimeout(() => {
        //document.getElementsByClassName("img-home")[0].style.display = "block";
    }, 500);


    useEffect(() => {


        document.getElementsByClassName("title-slider")[0].style.display = "none";
        document.getElementsByClassName("caption-p")[0].style.display = "none";
        document.getElementsByClassName("container-slider")[0].classList.add("animate__fadeInRight");
        //document.getElementsByClassName("img-home")[0].style.display = "none";
        document.getElementsByClassName("img-logo ")[0].style.display = "none";





        document.getElementById("line-slide").style.display = "none";
        setTimeout(() => {
            document.getElementsByClassName("caption-p")[0].style.display = "block";

            document.getElementsByClassName("img-logo ")[0].style.display = "block";
            document.getElementById("line-slide").style.display = "block";

        }, 1100);

    })


    return (
        <div id='home' className='p-0 m-0'>
            <div className='container-slider animate__animated container-fluid m-0 p-0' id="container-slider">
                <div className='img-logo text-center animate__animated animate__zoomIn animate__slow'>
                    <img src={props.logo} alt='logo' />

                </div>

                <div className='container-fluid m-0 p-0 img-home'>
                    <img src={props.imgURL} alt={props.alt} />
                </div>
                <div className='container-fluid m-0 p-0 bg-slider'></div>

                <div className='m-0 p-0 slider-body '>

                    <div className='thing m-0 p-0 '>
                        <div className="title-slider container animate__animated animate__fadeInDown">
                            <h1 className='caption text-white' >{props.title} </h1>
                        </div>
                        <div className='line bg-color ' id="line-slide"></div>

                        <div className='description p-2 animate__animated text-light'>

                            <p className="caption-p animate__animated  animate__fadeInUp animate__slow"> {props.caption} <span onClick={props.onClick} id={props.moreid}>{props.more}</span> </p>
                        </div>


                    </div>

                </div>


            </div>

        </div>
    )
}