import './index.scss'
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';

function ListItem(props) {

    return (
        <div className={'text-yellow'}>
            <span key={''} ><i className='fa fa-circle-check text-yellow '></i> {props.name} <br /></span>
        </div>
    )
}

function Component(props) {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    const [onScroll, setOnScroll] = React.useState(`${props.item.seq} hidden`);

    useEffect(() => {

        if (inView) {
            setOnScroll('show');
        }
    }, [inView]);

    return (<>

        <div className={`card my-2 mid-animation mx-2  ${onScroll} `} ref={ref} >
            <img className="card-img-top" src={props.item.url} alt="Card image cap" />
            <div className="card-body bg-white m-0 p-0 text-left">
                <h5 className="card-title p-0 mx-1 my-2"><b>{props.item.title}</b></h5>
                <p className="card-text px-1">{props.item.menu}</p>
                <a href="/profile" className="btn text-white rounded-pill shadow mx-2 mb-1 bg-theme">Learn More</a>
            </div>
        </div>
    </>
    )
}

export default Component;