import React, { useState } from 'react'
import ServiceSlider from './ServiceSlider'
import img from '../../assets/img/plantHire.jpg' //plantHire.jpg
import plant from '../../assets/img/plantHire.jpg' //
import Transport from '../../assets/img/trucksside.jpg' //
import planthire from '../../assets/img/20230817_113245.jpg' //
import petrol from '../../assets/img/petrol.jpg'
import sand from '../../assets/img/sand.jpg'
import stone from '../../assets/img/ADT_Truck.jpeg'
import './service.scss'

const Services = () => {

    const [items, setitems] = useState([
        
        { id: 6, title: "Transport/Logistics", img: Transport, 
        description: ["Material handling", 
                        "34 ton side tippers ",
                         "50 ton side tippers ", 
                         "10 cube tippers ", 
                         "18 Cube tippers"] },
                         { id: 4, title: "Plant Hire", img: planthire, description: ["Excavators", "Frontend Load", "ADT Dump Truck", "TLB"] },
        
        
        {
            id: 1,
            title: "Scrushing and Screening",
            img: sand, description: [
                "Crusher Dust"
            ]
        },


        {
            id: 2,
            title: "Plant Hire",
            img: stone, description: [
                "ADT Dump Truck"
            ]
        },
    ]);

    return (
        <div id='services' className='py-4'>
            <div className='container'>
                <div className='services-body p-0 mt-3 text-center bg-white' >
                    <h2 > Our Services</h2>
                    <div className='bg-secondary text-white rounded px-1 py-1 my-2'><h3>1. Mining and Logistics Services</h3></div>
                    <ServiceSlider items={items} />
                </div>
            </div>
        </div>
    )
}

export default Services