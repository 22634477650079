import React from 'react';
import './footer.scss';
import img from '../../assets/img/logo.jpg';

const Footer = () => {
    return (
        <div id='footer' className='py-3 position-relative'>

            <div className='container position-relative'>
                <ul className='list-unstyled d-flex justify-content-center'>
                    <li><a href="facebook"><i className='fab fa-facebook-f fa-fw'></i></a></li>
                    <li><a href="twitter"><i className='fab fa-twitter fa-fw'></i></a></li>
                    <li><a href="whatsapp"><i className='fab fa-whatsapp fa-fw'></i></a></li>
                    <li><a href="tel:+27728284205"><i className='fas fa-phone fa-fw'></i></a></li>
                </ul>
                <hr className='w-90 mx-auto bg-white border border-light' />

                <ul className='list-unstyled d-flex justify-content-center footer-navbar'>
                    <li><a href="/home">Home</a></li>
                    <li><a href="#work">Projects</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#Contact">Contact Us</a></li>
                </ul>
                <hr className='w-90 mx-auto bg-white border border-light d-hide' />
                <ul className='text-white footer-contact justify-content-between'>

                    <li className='text-left my-1'>
                        <span className='bg-secondary rounded px-3 py-1 text-white '> Operation Contacts </span>
                        <br />
                        <i className="fa-solid fa-phone text-white mx-1"></i>
                        <a href="tel:+27728284205" className='text-small'><small>+27 72 828 4205</small></a> /
                        <a href="tel:+27793704970"><small>+27 79 370 4970 </small></a>
                    </li>
                    <li className='text-left my-1'>
                        <span className='bg-secondary rounded px-3 py-1 text-white '> Admin Contacts </span>
                        <br />
                        <i className="fa-solid fa-phone text-white mx-1"></i>
                        <a href="tel:+27832541762" className='text-small'><small>083 254 1762</small></a>
                    </li>
                </ul>


                <div className='d-flex justify-content-center subscribe mt-4'>
                    <div>
                        <i className='fas fa-envelope'></i>
                    </div>
                    <div>
                        <input type="text" placeholder='Enter your email here...' name='Subscribeemail' />
                    </div>
                    <div>
                        <a href="Subscribe">Subscribe</a>
                    </div>
                </div>
                <p className='copyright text-center mt-3'>Copyright © 2023 All rights reserved -KGA Logistics </p>
            </div>
            {/* <div className='position-absolute  footer-end'>
                <img src={img} alt="" />
            </div> */}
        </div>
    )
}

export default Footer