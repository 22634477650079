import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import img from '../../assets/img/20230817_113245.jpg';

import './works.scss'

const Work = () => {

    const [workContent, setworkContent] = useState([
        {
            id: 1,
            type: "Scrushing and Screening", projects: [
                {
                    id: 1,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 2,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 3,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 4,
                    title: "Description here",
                    link: "#",
                    image: img
                }
            ]
        }, {
            id: 2,
            type: "Plant Hire", projects: [
                {
                    id: 2,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 3,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 4,
                    title: "Description here",
                    link: "#",
                    image: img
                }
            ]
        },
        {
            id: 3,
            type: "Open Cast Mining", projects: [
                {
                    id: 1,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 2,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 3,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 4,
                    title: "Description here",
                    link: "#",
                    image: img
                }
            ]
        },

        {
            id: 5,
            type: "Transport / Logistics", projects: [
                {
                    id: 1,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 2,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 3,
                    title: "Description here",
                    link: "#",
                    image: img
                }, {
                    id: 4,
                    title: "Description here",
                    link: "#",
                    image: img
                }
            ]
        }
    ]);

    return (
        <div id='work' className='py-4 my-4'>
            <div className='container'>
                <h2 className='text-uppercase text-center fw-bold'>our work</h2>
                <hr />
                <Tabs className="mt-4">
                    <TabList>
                        {
                            workContent.map((item, idx) => (

                                <Tab >{item.type}</Tab>

                            ))
                        } </TabList>
                    {
                        workContent.map((item, index) => (<TabPanel key={item.id} className="px-3">
                            <h3>{index + 1}. {item.type} Projects</h3>

                            <div className='row py-4 border'>
                                {item.projects.map((item) => (
                                    <div key={item.id} className="col-xl-3 col-lg-6 col-sm-12" data-aos="fade-down">
                                        <div className='item position-relative mb-3 hover-zoom'>
                                            <img src={item.image} alt={item.title} className="w-100" />
                                            <p className='project-item-title'>{item.title}</p>
                                            <a className='project-item-anchor' href={item.link}>VIEW PROJECT</a>
                                        </div>
                                    </div>
                                ))

                                }
                            </div>


                        </TabPanel>))
                    }

                </Tabs>

            </div>
        </div>
    )
}

export default Work