import Aos from 'aos';
import React, { useEffect, useState } from 'react';
import img from '../../assets/img/trucksside.jpg';
import { Link } from 'react-router-dom';
import Button from '../Button';
import './index.scss';
import Footer from '../footer/Footer';
import about1 from '../../assets/img/office.jpeg'
import about2 from '../../assets/img/20230817_113325.jpg'
import about3 from '../../assets/img/offices.jpeg'

const Transport = () => {

    const [readMore, setReadMore] = useState(false); //set readMore to be false by default

    //collapse the content 

    let readMore1 = `
    The Kgagamela Holdings offers comprehensive, value-added services to create an environment that minimizes risk and maximizes returns for our customers - allowing projects to be delivered on time and on budget, with no surprises. Our collaborative project approach sets the standard for communication with our customers to provide client-driven solutions and satisfaction.

    We pride ourselves on being very flexible and reacting quickly to customer needs as well as market demands. We are a service organization whose mission is to provide efficient solutions to our customers’ challenges.
`;
    useEffect(() => {
        Aos.init({ duration: 1500 });

        let el1 =  document.getElementById("header-bar");

        el1.classList.remove("in-viewport");
        ;
       el1.classList.add("out-viewport");
       el1.classList.remove("animate__fadeInDown");
       
         el1.classList.add("animate__fadeInDown");
        // el4.style.display = "none";


    }, [])

    let scrool = () => {
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }

    const about = [
        {
            name: `Since 2004, our customers have come to know and trust the Kgagamela Holdings Standard for Construction. Our core values of integrity, reliability and innovation are integral to delivering a superior customer experience. We provide economical and efficient solution to our client’s challenges. We take ownership of
             the issues and understand our responsibility and commitment to our clients.`,
            url: about1,
            title: 'Our Values'
        },
        {
            name: `Kgagamela Holdings is a full-service construction management and general 
            contracting firm that operate mainly in the Limpopo. We are a vertically integrated organization, composed of a variety of business units organized around industry sectors. The business units are very diverse and each unit specializes in its own core industry. This structure allows each division to operate and develop expertise based on the demands of its specific industry. It also provides stability 
            for the company as market forces vary within different market sectors.`,
            url: about2,
            title: 'Core Services'
        },
        {
            name: `The Kgagamela Holdings offers comprehensive, value-added services to create an
             environment that minimizes risk and maximizes returns for our customers - allowing
              projects to be delivered on time and on budget, with no surprises. Our collaborative 
              project approach sets the standard for 
            communication with our customers to provide client-driven solutions and satisfaction.`,
            url: about3,
            title: 'Why Choose Us'
        }
    ];

    return (
        <>
       <div id='Contact' className='py-4 mt-4 mx-0 w-100'>
            <div className='container-fluid mt-4 mx-0 '>
                <h2 className='text-center fw-bold text-uppercase mb-4 mx-0'>  Mining Sector</h2>
                <div className=' py-3 px-0 mx-0 w-100'>
                    <div className='px-3 py-2 mx-auto  text-center bg-second-theme text-white transport-btn'>
                    <b>THE SERVICE AND MAINTENANCE OF SMELTERS AND MINE</b>
                    </div>
                    <div className='row align-items-center w-100'>
                    <div className='col-xl-6 mt-4'>
                        <div className='item' data-aos="fade-up">
                            <img src={about2} className="w-100 rounded mb-4 mb-xl-0" alt="" />
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='item ' data-aos="fade-down">
                          
                              

                                    <p>
                                    Kgagamela Holdings provides process and engineering services, and specialized equipment to the ferroalloy industry in South Africa. It engages in the design, construction, and installation of ferroalloy plants and maintenance that produce raw materials for stainless and mild steel production. The company’s portfolio includes high carbon and charged chrome ferrochrome, high and medium carbon ferromanganese, silicomanganese, ferrosilicon, and ferrovanadium plants for various processes, such as ore agglomeration, sintering, smelting, and product handling. It also designs and supplies equipment, including charging chutes, bus-tubes, electrode systems, furnace roofs, shells and furnace linings, electrode contact shoes, and pressure rings.

                                    </p>

                           
                        </div>
                    </div>
                </div>
                   
                  
                </div>
                <hr />
                <div className='border mx-2 p-2'>
                    <p>The applications for these processes range from ore agglomeration and pre-treatment (such as sintering) through to smelting and product handling. Dependant on the application, Kgagamela Holdings can offer different technologies for these products, more specifically:</p>
                </div>
            </div>
        </div>

        <Footer />
        </>
    )
}

export default Transport